import useSWR from "swr";

import { authClient } from "services/authClient";

import { CategoryType } from "types";

export function useAuthAllCategories<Data = any, Error = any>() {
  const url = `/backoffice/category?limit=30`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((category: CategoryType) => {
      return {
        ...category,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

interface useAuthCategoriesProps {
  page?: number | string;
}

export function useAuthCategories<Data = any, Error = any>({
  page,
}: useAuthCategoriesProps) {
  const url = `/backoffice/category?page=${page ?? 1}&limit=30`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map((category: CategoryType) => {
      return {
        ...category,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}
