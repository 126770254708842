import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { PurchaseType } from "types";
import { useAuthSales } from "services/hooks/sale";
import useDebounce from "hooks/useDebounce";

import { Sidebar } from "components/Sidebar";
import { Header } from "components/Header";
import { PaginationFilterComponent } from "components/PaginationFilter";

import { paramsToString } from "utils/searchOptions";

import { Table } from "./components/Table";

import { Container, ContainerItemsTable, Wrapper } from "./styles";
import { removeSpecialCharactersAndSpaces } from "utils/masks";

interface PurchasesInterface {
  results: PurchaseType[];
  count: number;
}

export default function SalesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data } = useAuthSales<PurchasesInterface>({
    page,
    filters: debouncedSearch,
  });

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },

    [searchParams, setSearchParams]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, searchParams, setSearchParams]);

  const filterAdvancedStatus = searchParams.get("filter_advanced_status");

  const idFilter = searchParams.get("_id") ?? "";
  const dataLte = searchParams.get("data_lte") ?? "";
  const dataGte = searchParams.get("data_gte") ?? "";
  const valueLte = searchParams.get("value_lte") ?? "";
  const valueGte = searchParams.get("value_gte") ?? "";
  const eventId = searchParams.get("event") ?? "";
  const ticketId = searchParams.get("ticket_id") ?? "";
  const document = searchParams.get("organizer_document") ?? "";
  const typePayment = searchParams.get("payment_type") ?? "";
  const purchaseStatus = searchParams.get("payment_status") ?? "";
  const organizerId = searchParams.get("organizer_id") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "1") {
      const paramsString = new URLSearchParams(
        paramsToString({
          _id: idFilter,
          data_lte: dataLte,
          data_gte: dataGte,
          value_lte: valueLte,
          value_gte: valueGte,
          event_id: eventId,
          ticket_id: ticketId,
          organizer_document: removeSpecialCharactersAndSpaces(document),
          payment_type: typePayment,
          payment_status: purchaseStatus,
          organizer_id: organizerId,
        })
      );

      setParamsString(paramsString);
    }
  }, [
    dataGte,
    dataLte,
    document,
    eventId,
    filterAdvancedStatus,
    idFilter,
    organizerId,
    purchaseStatus,
    ticketId,
    typePayment,
    valueGte,
    valueLte,
  ]);

  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "0") {
      const allowedToRemove = [
        "document",
        "phone",
        "cell_phone",
        "business_phone",
        "address_zipcode",
        "agent_document",
        "agent_phone",
        "support_whatsapp",
        "address_zipcode",
        "organizer_document",
      ];
      const isDocumentOrPhone = allowedToRemove.includes(filterId);

      const paramsString = new URLSearchParams(
        paramsToString({
          simple_filter_id: filterId,
          simple_filter_text: isDocumentOrPhone
            ? removeSpecialCharactersAndSpaces(filterText)
            : filterText,
        })
      );

      setParamsString(paramsString);
    }
  }, [filterAdvancedStatus, filterId, filterText]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />

        <ContainerItemsTable>
          <h1>Vendas</h1>

          <h3 className="title">Vendas</h3>
          <Table data={data?.results} total={data?.count ?? null} />

          <div className="pagination">
            <PaginationFilterComponent
              current={page}
              onChange={onChange}
              total={data?.count ?? 0}
            />
          </div>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
