/* eslint-disable @typescript-eslint/no-unused-vars */
import { Spin } from "antd";
import { Modal } from "components/Modal";
import { Colors } from "constants/colors";
import { BsPlus } from "react-icons/bs";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";

import {
  ButtonAddFile,
  ButtonCancel,
  ButttonEdit,
  ContainerInputDocument,
  ContainerModalAddDocument,
  ContainerNormalButtons,
  FormFull,
  FormLabel,
} from "./styles";

import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { OrganizerType } from "types";
import { EditOrganizer, SendDocumentOrganizer } from "services/hooks/organizer";
import { removeSpecialCharactersAndSpaces } from "utils/masks";

type ErrorPutOrganizer = {
  response: {
    data: {
      error: string;
    };
  };
};

interface DocumentFormData {
  category: string;
}

const documentFormSchema = yup.object().shape({
  category: yup.string().required("Categoria obrigatória"),
});

interface ModalAddDocumnentProps {
  idOrganizer: string;
  organizer: OrganizerType;
  isModalVisible: boolean;
  handleCancel(): void;
  handleOk(): void;
  handleAddDocumentCancel(): void;
}

export function ModalAddDocument({
  idOrganizer,
  organizer,
  isModalVisible,
  handleCancel,
  handleOk,
  handleAddDocumentCancel,
}: ModalAddDocumnentProps) {
  const { mutate } = useSWRConfig();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DocumentFormData>({
    resolver: yupResolver(documentFormSchema),
  });

  const [step, setStep] = useState(0);

  const [fileFrontIdentity, setFileFrontIdentity] = useState<
    File | undefined
  >();
  const [previewFrontIdentity, setPreviewFrontIdenty] = useState<string>();

  useEffect(() => {
    if (!fileFrontIdentity) {
      setPreviewFrontIdenty(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileFrontIdentity);
    setPreviewFrontIdenty(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [fileFrontIdentity]);

  const onSelectFileFrontIdentity = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFileFrontIdentity(undefined);
      return;
    }

    setFileFrontIdentity(e.target.files[0]);
  };

  const [fileBackIdentity, setFileBackIdentity] = useState<File | undefined>();
  const [previewBackIdentity, setPreviewBackIdentity] = useState<string>();

  useEffect(() => {
    if (!fileBackIdentity) {
      setPreviewBackIdentity(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileBackIdentity);
    setPreviewBackIdentity(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [fileBackIdentity]);

  const onSelectFileBackIdentity = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFileBackIdentity(undefined);
      return;
    }

    setFileBackIdentity(e.target.files[0]);
  };

  const [fileCnpjProof, setFileCnpjProof] = useState<File | undefined>();
  const [previewCnpjProof, setPreviewCnpjProof] = useState<string>();

  useEffect(() => {
    if (!fileCnpjProof) {
      setPreviewCnpjProof(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileCnpjProof);
    setPreviewCnpjProof(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [fileCnpjProof]);

  const onSelectFileCnpjProof = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFileCnpjProof(undefined);
      return;
    }

    setFileCnpjProof(e.target.files[0]);
  };

  const [fileAddressProof, setFileAddressProof] = useState<File | undefined>();
  const [previewAddressProof, setPreviewAddressProof] = useState<string>();

  useEffect(() => {
    if (!fileAddressProof) {
      setPreviewAddressProof(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileAddressProof);
    setPreviewAddressProof(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [fileAddressProof]);

  const onSelectFileAddressProof = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFileAddressProof(undefined);
      return;
    }

    setFileAddressProof(e.target.files[0]);
  };

  const [loadingAddDocument, setLoadingAddDocument] = useState(false);

  const handleAddDocumentOrganizer = async () => {
    try {
      setLoadingAddDocument(true);
      let formData1 = new FormData();
      if (fileFrontIdentity) {
        formData1.append("document", fileFrontIdentity);
      }

      const response1 = await SendDocumentOrganizer(formData1);

      const { url: url1 } = response1.data;

      let formData2 = new FormData();
      if (fileBackIdentity) {
        formData2.append("document", fileBackIdentity);
      }

      const response2 = await SendDocumentOrganizer(formData2);

      const { url: url2 } = response2.data;

      let formData3 = new FormData();
      if (fileCnpjProof) {
        formData3.append("document", fileCnpjProof);
      }

      const response3 = await SendDocumentOrganizer(formData3);

      const { url: url3 } = response3.data;

      let formData4 = new FormData();

      if (fileAddressProof) {
        formData4.append("document", fileAddressProof);
      }

      const response4 = await SendDocumentOrganizer(formData4);

      const { url: url4 } = response4.data;

      const {
        id,
        name,
        nickname,
        agent_name,
        agent_document,
        agent_birthdate,
        agent_email,
        agent_phone,
        address_zipcode,
        address_country,
        address_district,
        address_state,
        address_complement,
        address_city,
        address_street,
        address_number,
        business_name,
        description,
        business_email,
        business_phone,
        fee,
      } = organizer;

      const credentials = {
        name,
        nickname: nickname ?? "",
        agent_name,
        agent_document: removeSpecialCharactersAndSpaces(agent_document),
        agent_birthdate,
        agent_email,
        agent_phone: removeSpecialCharactersAndSpaces(agent_phone),
        address_zipcode,
        address_country,
        address_district,
        address_state,
        address_complement,
        address_city,
        address_street,
        address_number,
        business_name,
        description,
        business_email,
        business_phone,
        fee,
        organizer_document: [
          {
            document_url: url1,
            document_type: 1,
            organizer_id: id,
          },
          {
            document_url: url2,
            document_type: 1,
            organizer_id: id,
          },
          {
            document_url: url3,
            document_type: 4,
            organizer_id: id,
          },
          {
            document_url: url4,
            document_type: 3,
            organizer_id: id,
          },
        ],
      };

      await EditOrganizer({ idOrganizer, credentials });

      // await authClient.patch(`/backoffice/organizer/edit/${idOrganizer}`, {
      //   name,
      //   nickname: nickname ?? "",
      //   agent_name,
      //   agent_document,
      //   agent_birthdate,
      //   agent_email,
      //   agent_phone,
      //   address_zipcode,
      //   address_country,
      //   address_district,
      //   address_state,
      //   address_complement,
      //   address_city,
      //   address_street,
      //   address_number,
      //   business_name,
      //   description,
      //   business_email,
      //   business_phone,
      //   fee,
      //   organizer_document: [
      //     {
      //       document_url: url1,
      //       document_type: 1,
      //       organizer_id: id,
      //     },
      //     {
      //       document_url: url2,
      //       document_type: 1,
      //       organizer_id: id,
      //     },
      //     {
      //       document_url: url3,
      //       document_type: 4,
      //       organizer_id: id,
      //     },
      //     {
      //       document_url: url4,
      //       document_type: 3,
      //       organizer_id: id,
      //     },
      //   ],
      // });

      mutate(`/backoffice/organizer/${idOrganizer}`);

      setLoadingAddDocument(false);
      handleAddDocumentCancel();
      alert(`Documento adicionado com sucesso`);
    } catch (err) {
      setLoadingAddDocument(false);
      const response = err as ErrorPutOrganizer;

      const { error } = response.response.data;

      alert(error);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <Modal
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      handleOk={handleOk}
    >
      <ContainerModalAddDocument
        onSubmit={handleSubmit(handleAddDocumentOrganizer)}
      >
        <h2 className="title">Adicionar Documentos</h2>

        <p className="description">Adicione todos os documentos necessários.</p>

        <div className="header-content">
          <h3>Upload de documentos</h3>

          <h4>
            Especificações: arquivo do tipo PNG ou JPEG, com tamanho máximo
            recomendado de 4MB.
          </h4>
        </div>

        <FormFull>
          <ContainerInputDocument isEditing>
            <FormLabel>
              <h2>Documento de identidade (frente)</h2>
            </FormLabel>

            <div className="row">
              <div className="card">
                {fileFrontIdentity ? (
                  <h3 className="text">{fileFrontIdentity?.name}</h3>
                ) : null}
              </div>

              <input
                id="file1"
                type="file"
                style={{ display: "none" }}
                onChange={onSelectFileFrontIdentity}
              />

              <ButtonAddFile htmlFor="file1">
                <BsPlus color={Colors.white} size={23} />
              </ButtonAddFile>
            </div>
          </ContainerInputDocument>
        </FormFull>

        {step > 0 ? (
          <FormFull>
            <ContainerInputDocument isEditing>
              <FormLabel>
                <h2>Documento de identidade (verso)</h2>
              </FormLabel>

              <div className="row">
                <div className="card">
                  {fileBackIdentity ? (
                    <h3 className="text">{fileBackIdentity?.name}</h3>
                  ) : null}
                </div>
                <input
                  id="file2"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onSelectFileBackIdentity}
                />

                <ButtonAddFile htmlFor="file2">
                  <BsPlus color={Colors.white} size={23} />
                </ButtonAddFile>
              </div>
            </ContainerInputDocument>
          </FormFull>
        ) : null}

        {step > 1 ? (
          <FormFull>
            <ContainerInputDocument isEditing>
              <FormLabel>
                <h2>Comprovente de situação cadastral (CNPJ)</h2>
              </FormLabel>

              <div className="row">
                <div className="card">
                  {fileCnpjProof ? (
                    <h3 className="text">{fileCnpjProof?.name}</h3>
                  ) : null}
                </div>

                <input
                  id="file3"
                  type="file"
                  style={{ display: "none" }}
                  onChange={onSelectFileCnpjProof}
                />

                <ButtonAddFile htmlFor="file3">
                  <BsPlus color={Colors.white} size={23} />
                </ButtonAddFile>
              </div>
            </ContainerInputDocument>
          </FormFull>
        ) : null}

        {step > 2 ? (
          <FormFull>
            <ContainerInputDocument isEditing>
              <FormLabel>
                <h2>Comprovante de residência</h2>
              </FormLabel>

              <div className="row">
                <div className="card">
                  {fileAddressProof ? (
                    <h3 className="text">{fileAddressProof?.name}</h3>
                  ) : null}
                </div>

                <input
                  id="file4"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  onChange={onSelectFileAddressProof}
                />

                <ButtonAddFile htmlFor="file4">
                  <BsPlus color={Colors.white} size={23} />
                </ButtonAddFile>
              </div>
            </ContainerInputDocument>
          </FormFull>
        ) : null}

        {step === 0 ? (
          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
              <span>Cancelar</span>
            </ButtonCancel>

            <ButttonEdit
              type="button"
              disabled={!fileFrontIdentity}
              hasActive={!!fileFrontIdentity}
              onClick={() => setStep(step + 1)}
            >
              <Spin spinning={isSubmitting} indicator={antIcon} />

              <span>Prosseguir</span>
            </ButttonEdit>
          </ContainerNormalButtons>
        ) : null}

        {step === 1 ? (
          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
              <span>Cancelar</span>
            </ButtonCancel>

            <ButttonEdit
              type="button"
              disabled={!fileBackIdentity}
              hasActive={!!fileBackIdentity}
              onClick={() => setStep(step + 1)}
            >
              <Spin spinning={isSubmitting} indicator={antIcon} />

              <span>Prosseguir</span>
            </ButttonEdit>
          </ContainerNormalButtons>
        ) : null}

        {step === 2 ? (
          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
              <span>Cancelar</span>
            </ButtonCancel>

            <ButttonEdit
              type="button"
              disabled={!fileCnpjProof}
              hasActive={!!fileCnpjProof}
              onClick={() => setStep(step + 1)}
            >
              <Spin spinning={isSubmitting} indicator={antIcon} />

              <span>Prosseguir</span>
            </ButttonEdit>
          </ContainerNormalButtons>
        ) : null}

        {step === 3 ? (
          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
              <span>Cancelar</span>
            </ButtonCancel>

            <ButttonEdit
              type="button"
              disabled={!fileAddressProof}
              hasActive={!!fileAddressProof}
              onClick={handleAddDocumentOrganizer}
            >
              <Spin spinning={loadingAddDocument} indicator={antIcon} />

              <span>Salvar</span>
            </ButttonEdit>
          </ContainerNormalButtons>
        ) : null}
      </ContainerModalAddDocument>
    </Modal>
  );
}
