import { useState } from "react";

import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { UserType } from "types";

import { Colors } from "constants/colors";
import { ADVANCED_FILTERS, SIMPLE_FILTERS } from "constants/filters/users";

import {
  Container,
  Card,
  Tr,
  Status,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  LinkStyled,
  LinkStyledMobile,
} from "./styles";

import { StatusComponent } from "components/Status";
import { HeaderFilterComponent } from "components/HeaderFilter";
import { maskCpfOrCnpj, phoneToString } from "utils/masks";

interface TableProps {
  data?: UserType[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activeUser, setActiveUser] = useState<UserType | null>(null);

  function handleSelectedUser(user: UserType, active: boolean) {
    if (active) {
      setActiveUser(user);

      return;
    }

    setActiveUser(null);
  }

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          total={total ?? 0}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          label="do usuário"
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>NOME</th>
                <th className="item-show">CPF/CNPJ</th>
                <th>CONTATO</th>
                <th>DETALHES</th>
                <th>VERIFICADO</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data
                ? Array.from({ length: 20 }).map((_) => (
                    <Tr active={false}>
                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>
                    </Tr>
                  ))
                : null}

              {data &&
                data?.map((user, i) => (
                  <Tr key={i} active={user?._id === activeUser?._id}>
                    <td>
                      <div className="all">
                        <Link to={`/users/${user?._id}`}>
                          <strong>{user?._id}</strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <Link to={`/users/${user?._id}`}>
                          <strong>{user?.name}</strong>
                        </Link>
                      </div>
                    </td>

                    <td className="item-show">
                      <div className="all">
                        <strong>{maskCpfOrCnpj(user?.document)}</strong>
                      </div>
                    </td>

                    <td>
                      <div className="column-one">
                        <strong className="first">{phoneToString(user?.cell_phone ?? "")}</strong>
                        <strong className="final">
                          {user?.email
                            ? user.email.length > 22
                              ? `${user.email.substring(0, 22)}...`
                              : user.email
                            : ""}
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong className="type">
                          Tipo:{" "}
                          <small>
                            {user?.organizers && user?.organizers?.length > 0
                              ? "Organizador"
                              : "Usuario"}
                          </small>
                        </strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        {user?.verified ? (
                          <StatusComponent
                            color="green"
                            content="SIM"
                            size="small"
                          />
                        ) : (
                          <StatusComponent
                            color="red"
                            content="NÃO"
                            size="small"
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="all">
                        <div className="column">
                          {activeUser?._id === user?._id ? (
                            <FiChevronUp
                              onClick={() => handleSelectedUser(user, false)}
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          ) : (
                            <FiChevronDown
                              onClick={() => handleSelectedUser(user, true)}
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          )}

                          {activeUser?._id === user?._id ? (
                            <LinkStyled to={`/users/${user?._id}`}>
                              <span>Detalhes</span>
                            </LinkStyled>
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </Tr>
                ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhum Usuário Encontrado</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            total={total ?? 0}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            label="do usuário"
          />

          <List>
            {data &&
              data?.map((user) => (
                <Item>
                  <Link to={`/users/${user?._id}`}>
                    <h1 className="first">{user.name}</h1>
                  </Link>

                  <h2>{maskCpfOrCnpj(user.document)}</h2>

                  <p>{user.email}</p>

                  <span className="text">{user.cell_phone}</span>

                  <div className="chevron-mobile">
                    <div>
                      <span className="text">verificado</span>
                      {user?.verified ? (
                        <StatusComponent
                          color="green"
                          content="SIM"
                          size="small"
                        />
                      ) : (
                        <StatusComponent
                          color="red"
                          content="NÃO"
                          size="small"
                        />
                      )}
                    </div>

                    {activeUser?._id === user?._id ? (
                      <FiChevronUp
                        onClick={() => handleSelectedUser(user, false)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    ) : (
                      <FiChevronDown
                        onClick={() => handleSelectedUser(user, true)}
                        style={{ cursor: "pointer" }}
                        color={Colors.secondary80}
                        size={22}
                      />
                    )}
                  </div>

                  {activeUser?._id === user?._id ? (
                    <div className="footer-details">
                      <LinkStyledMobile to={`/users/${user?._id}`}>
                        <span>Detalhes</span>
                      </LinkStyledMobile>
                    </div>
                  ) : null}
                </Item>
              ))}

            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data && data.length === 0 ? (
              <div className="container-empty">
                <h3>Nenhum Usuário Encontrado</h3>
              </div>
            ) : null}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
