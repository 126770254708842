import { useCallback, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { useAuthTickets } from "services/hooks/ticket";

import { TicketType } from "types";

import { Header } from "components/Header";
import { Sidebar } from "components/Sidebar";
import { PaginationFilterComponent } from "components/PaginationFilter";

import { Container, ContainerItemsTable, Wrapper } from "./styles";

import { Table } from "./components/Table";
import { paramsToString } from "utils/searchOptions";
import useDebounce from "hooks/useDebounce";
import { removeSpecialCharactersAndSpaces } from "utils/masks";

interface TicketsInterface {
  results: TicketType[];
  count: number;
}

export default function TicketsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data } = useAuthTickets<TicketsInterface>({
    page,
    filters: debouncedSearch,
  });

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },

    [searchParams, setSearchParams]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, searchParams, setSearchParams]);

  const filterAdvancedStatus = searchParams.get("filter_advanced_status");

  const idFilter = searchParams.get("_id") ?? "";
  const eventId = searchParams.get("event_id") ?? "";
  const typeTicket = searchParams.get("type_ticket") ?? "";
  const dataLte = searchParams.get("created_lte") ?? "";
  const dataGte = searchParams.get("created_gte") ?? "";
  const organizerName = searchParams.get("organizer_name") ?? "";
  const organizerId = searchParams.get("organizer_id") ?? "";
  const name = searchParams.get("holder_name") ?? "";
  const addressState = searchParams.get("address_state") ?? "";
  const addressCity = searchParams.get("address_city") ?? "";
  const status = searchParams.get("status") ?? "";
  const hasDiscount = searchParams.get("has_discount") ?? "";
  const transfer = searchParams.get("transfer") ?? "";
  const age = searchParams.get("age") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "1") {
      const paramsString = new URLSearchParams(
        paramsToString({
          _id: idFilter,
          event_id: eventId,
          created_lte: dataLte,
          created_gte: dataGte,
          organizer_name: organizerName,
          organizer_id: organizerId,
          holder_name: name,
          address_state: addressState,
          address_city: addressCity,
          status,
          has_discount: hasDiscount,
          transfer,
          age,
          type_ticket: typeTicket,
        })
      );

      setParamsString(paramsString);
    }
  }, [
    addressCity,
    addressState,
    age,
    dataGte,
    dataLte,
    eventId,
    filterAdvancedStatus,
    hasDiscount,
    idFilter,
    name,
    organizerId,
    organizerName,
    status,
    transfer,
    typeTicket,
  ]);

  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterAdvancedStatus === "0") {
      const allowedToRemove = [
        "document",
        "phone",
        "cell_phone",
        "business_phone",
        "address_zipcode",
        "agent_document",
        "agent_phone",
        "support_whatsapp",
        "address_zipcode",
        "organizer_document",
      ];
      const isDocumentOrPhone = allowedToRemove.includes(filterId);

      const paramsString = new URLSearchParams(
        paramsToString({
          simple_filter_id: filterId,
          simple_filter_text: isDocumentOrPhone
            ? removeSpecialCharactersAndSpaces(filterText)
            : filterText,
        })
      );

      setParamsString(paramsString);
    }
  }, [filterAdvancedStatus, filterId, filterText]);

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />

        <ContainerItemsTable>
          <h1>Ingressos</h1>

          <h3 className="title">Ingressos</h3>
          <Table data={data?.results} total={data?.count ?? null} />

          <div className="pagination">
            <PaginationFilterComponent
              current={page}
              onChange={onChange}
              total={data?.count ?? 0}
            />
          </div>
        </ContainerItemsTable>
      </Container>
    </Wrapper>
  );
}
