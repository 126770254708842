import {
  returnStatusColorPurchase,
  returnStatusPurchase,
} from "utils/purchase";
import { formatPrice } from "utils/formatPrice";
import { LinkWithHover } from "styles/LinkWithHover";
import { Dropdown, DropDownContainer, LinkStyledLiLast } from "styles/Dropdown";
import { StatusComponent } from "components/Status";
import points from "assets/points.svg";
import eyes_secondary from "assets/eyes-secondary.svg";

import { Container } from "./styles";
import { useAuthEvent } from "services/hooks/event";

type PurchaseCardProps = {
  id: string;
  eventId?: string;
  status: string;
  total: number;
  onSelectPurchase: () => void;
  isActivePurchase: boolean;
}

type ShowEventType = {
  _id: string;
  name: string;
}

function PurchaseCard({ id, eventId, status, total, onSelectPurchase, isActivePurchase }: PurchaseCardProps) {
  const { data } = useAuthEvent<ShowEventType>(id);

  return (
    <Container>
      <div className="column">
        <header>
          <div className="second">
            <div className="local">
              <LinkWithHover to={`/sales/${id}`}>
                <h4>
                  <strong>ID da compra:</strong>{" "}
                  <span>{id}</span>
                </h4>
              </LinkWithHover>

              <LinkWithHover
                to={`/events/${eventId}`}
              >
                <h4>
                  <strong>Evento:</strong>{" "}
                  <span>
                    {data?.name ? data.name : "Não informado"}
                  </span>
                </h4>
              </LinkWithHover>

              <h4>
                <strong>Valor:</strong>{" "}
                <span>{formatPrice(total / 100)}</span>
              </h4>
            </div>
          </div>

          <div className="options">
            <StatusComponent
              content={returnStatusPurchase(status)}
              color={returnStatusColorPurchase(status)}
            />
            <DropDownContainer>
              <div
                className="area"
                onClick={onSelectPurchase}
              >
                <img src={points} alt="" />
              </div>

              {isActivePurchase ? (
                <Dropdown height="55px">
                  <ul>
                    <LinkStyledLiLast
                      className="last"
                      to={`/sales/${id}`}
                    >
                      <img src={eyes_secondary} alt="eye" />
                      <h5>Visualizar Compra</h5>
                    </LinkStyledLiLast>
                  </ul>
                </Dropdown>
              ) : null}
            </DropDownContainer>
          </div>
        </header>
      </div>
    </Container>
  )
}

export default PurchaseCard;
